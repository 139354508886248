import _ from 'lodash';
import utils from '@/helper/utils';
import { defineStore } from 'pinia';
import Membership from '@/lib/api/Membership';
import modalControler from '@/helper/modalControler';

export const membershipStore = defineStore('membershipStore', {
    state: () => ({
        customerId: null,
        products: [],
        subscriptions: [],
        isOrderSuccess: null,
        membershipList: [],
        subscriptionId:null,
        authKeyList:[],
        authKey:null,
        authKeyId:null,
    }),
    getters: {
        getCustomerId(state) {
            return state.customerId
        },
        getOrderState(state) {
            return state.isOrderSuccess
        },
    },
    actions: {
        storeCustomerId(customerId) {
            console.log(customerId)
            this.customerId = customerId
        },
        async getProducts() {
            try {
                const response = await Membership.getProducts()
                // console.log('products: ', response)
                if (response.data.code === 0) {     // success
                    this.products = response.data.result.content
                    // TODO : products가 여러개일 경우에는 다르게 처리 필요 
                    // => 현재는 MNC copilot only -> this.products[0]
                    this.membershipList = this.products[0].prices
                } else {    // fail

                }

            } catch (error) {

            }
        },
        async createOrder(membership) {
            try {
                // steppay 회원 체크
                const steppayUserInfo = await Membership.getCustomerInfo()
                // console.log('steppay info: ', steppayUserInfo.data)
                // steppay에 사용자 정보 없는 경우
                if (!steppayUserInfo.data.result) {
                    modalControler.ShowModalDialog('제3자정보 제공동의', 'Steppay에 개인정보가 제공됩니다.', '취소', '확인', async (val) => {
                        if (val) {
                            try {
                                const response = await Membership.agreeSteppayUse()
                                // console.log('steppay agree response: ', response)
                                if (response.data.code === 0) {     // success
                                    this.customerId = response.data.result.id
                                    modalControler.ShowSuccessToast('Steppay 등록완료')
                                    
                                    // 최초 등록 후, 바로 주문 창 가져오도록 action 다시 탐
                                    this.createOrder(membership)
                                }
                            } catch (error) {
                                console.log('Steppay 등록중 오류: ', error)
                                modalControler.ShowErrorToast('Steppay 등록 오류')
                            } finally {
                                console.log('After steppay registration: ', this.customerId)
                            }
                        }
                    })
                // Steppay에 사용자 정보 있는 경우
                } else {
                    this.customerId = steppayUserInfo.data.result.stepPayId
                }
                //console.log(this.customerId)

                let param = {
                    currency: "KRW",
                    items: [
                        {
                            productCode: this.products[0].code, // "product_NfTTMUIYl",
                            priceCode: membership.code //"price_6N2PLtpiv"
                        }
                    ],
                    customerId: this.customerId,

                }
                const response = await Membership.createOrder(param)
                // console.log("Membership order: ", response)
                if (response.data.success) {
                    let orderId = response.data.result.orderId
                    let baseUrl = window.location.origin
                    let param = {
                        successUrl: `${baseUrl}/membership/result?type=order_success`,
                        errorUrl: `${baseUrl}/membership/result?type=order_fail`
                    }
                    const orderUrlRes = await Membership.getOrderUrl(orderId, utils.makeParams(param))
                    // console.log('orderurl: ', orderId)
                    if (orderUrlRes.data.success) {
                        window.open(orderUrlRes.data.result)
                    } else {

                    }
                } else {
                    // TODO : 주문생성 실패 시
                }
            } catch (error) {
                //console.log("Membership order: ", error)
            }
        },
        // 구독 목록 조회
        async getSubscriptions(customerId) {
            try {

                const response = await Membership.getSubscriptions(customerId)
                this.subscriptions = response.data

            } catch (error) {
                console.error('Error subscriptions:', error);
            }
        },
        // 구독 목록 안에 난수키 조회
        async getAuthKey() {
            try {
                const response = await Membership.getAuthKey()
                this.authKeyList = response.data.result
            } catch (error) {
                console.error('Error authKey:', error);
            }
        },
        // 구독 취소
        async cancelSubscription(whenToCancel = "END_OF_PERIOD") {
            try {
                this.subscriptionId = this.subscriptions.result[0]?.id;  // 임시로 0번 인덱스 구독 선택
                const response = await Membership.cancelSubscription(this.subscriptionId, whenToCancel);
                //console.log('Cancel Subscription Response:', response);
            } catch (error) {
                console.error('Error cancelSubscription:', error);
            }
        },
        // 구독 목록 안에 난수키 생성 함수 추가
        async createAuthKey() {
            try {
                const param = {
                    allowedIp: null,  
                    expiryDtm: null,  
                };
                const response = await Membership.createAuthKey(param);
                    if (response.data.code === 0 ) {     // success            
                        console.log('인증키 생성 성공')
                    }
            } catch (error) {
                console.error('인증키 생성 에러', error);
                modalControler.ShowErrorToast('난수키 생성 오류')
            }
        },
        // 리프레시 토큰 생성
        async createRefreshToken(authKeyId) {
            try {
                const response = await Membership.createRefreshToken(authKeyId);
            } catch (error) {
                console.error('리프레시 토큰 에러', error);
            }
        },
    }
})