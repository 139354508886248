<template>
    <figure class="table-task__box scroll">
      <table class="table-task">
        <thead class="table-task__head">
          <tr>
            <th v-for="col in columns" :key="col.key" :style="{ width: col.width }">
              {{ col.label }}
            </th>
          </tr>
        </thead>
        <tbody class="table-task__body">
          <tr v-for="(row, index) in data" :key="'row-' + index" class="text-center">
            <td v-for="col in columns" :key="col.key" class="text-center">
              <slot :name="col.key" :row="row">{{ row[col.key] }}</slot>
            </td>
          </tr>
        </tbody>
      </table>
    </figure>
</template>

<script>
export default {
    name: 'Table',
    props: {
        columns: {
            type: Array,
            required: true,
            default: () => [],
        },
        data: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
};
</script>

