<template>
  <div class="community-wrap contents-wrap">
    <div class="content-inner">
      <section class="membership-box__section">
        <!--결제완료 시작-->
        <div v-if="resultType === 'order_success'" class="membership-box">
          <div class="membership-box__icon">
            <img src="../../assets/images/img-member-check.svg" alt="완료" />
          </div>
          <h3 class="membership-box__title">결제 완료</h3>
          <p class="membership-box__text">Code Assistant의 멤버십 회원이 되었습니다.</p>
          <div class="membership-box__btn">
            <v-btn @click="toMyMembership" class="btn btn-primary btn-full btn-medium">마이 멤버십 확인하기</v-btn>
          </div>
        </div>
        <!--결제완료 끝-->
        <!--결제실패 시작-->
        <div v-else-if="resultType === 'order_fail'" class="membership-box">
          <div class="membership-box__icon">
            <img src="../../assets/images/img-member-x.svg" alt="취소" />
          </div>
          <h3 class="membership-box__title">결제 실패</h3>
          <p class="membership-box__text">다시 한 번 시도해주세요.</p>
          <div class="membership-box__btn">
            <v-btn @click="toMembershipList" class="btn btn-primary btn-full btn-medium">메인 페이지로 이동하기</v-btn>
          </div>
        </div>
        <!--결제실패 끝-->
        <!--구독 취소 완료 시작-->
        <div v-else-if="resultType === 'cancel_success'" class="membership-box">
          <div class="membership-box__icon">
            <img src="../../assets/images/img-member-check.svg" alt="완료" />
          </div>
          <h3 class="membership-box__title">구독 취소 완료</h3>
          <p class="membership-box__text">이용해 주셔서 감사합니다.</p>
          <div class="membership-box__btn">
            <v-btn @click="toMembershipList" class="btn btn-primary btn-full btn-medium">메인 페이지로 이동하기</v-btn>
          </div>
        </div>
        <!--구독 취소 완료 끝-->
        <!--구독 취소 실패 시작-->
        <div v-else-if="resultType === 'cancel_fail'" class="membership-box">
          <div class="membership-box__icon">
            <img src="../../assets/images/img-member-x.svg" alt="취소" />
          </div>
          <h3 class="membership-box__title">구독 취소 실패</h3>
          <p class="membership-box__text">다시 한 번 시도해주세요.</p>
          <div class="membership-box__btn">
            <v-btn @click="toMembershipList" class="btn btn-primary btn-full btn-medium">메인 페이지로 이동하기</v-btn>
          </div>
        </div>
        <!--구독 취소 실패 끝-->
      </section>
    </div>
  </div>
</template>

<script>
import { 
  reactive, 
  computed,
} from '@vue/composition-api';



export default {
    name: 'MembershipResult',
    components: {
        
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            membershipList: []
        });

        const resultType = computed(() => route.query.type)

        const toMembershipList = () => {
          router.push({ name: 'MembershipList' })
        }

        const toMyMembership = () => {
          router.push({ name: 'CompetitionListProfile' })
        }


        return {
          router,
          resultType,
          toMembershipList,
          toMyMembership,
        }
    }
}
</script>