<template>
  <div>
    <!-- 구독 데이터가 없을 때 -->
    <div v-if="!subscriptions || subscriptions.length === 0" class="no-data">
      <p class="no-data__text">구독 중인 상품이 없습니다.</p>
      <div class="no-data__btn">
        <v-btn class="btn btn-medium btn-secondary" @click="$router.push({ name: 'MembershipList' })">멤버십 구독하기</v-btn>
      </div>
    </div>

    <!-- 구독 데이터가 있을 때 -->
    <Table v-else :columns="getColumns()" :data="[subscription]">
      <template #productName="{ row }">
        <span>
          <strong>{{ getWithoutPlanName(row.productName) }}</strong>
          <br />
          <span v-if="getPlanName(row.productName)">
            {{ getPlanName(row.productName) }}
          </span>
        </span>
      </template>
      <template #authKey="{ row }">
        <div class="btn-flex">
    <span class="mncpilot-authkey" :title="row.authKey">{{ row.authKey }}</span>
          <button v-if="row.authKey" type="button" class="btn btn-copy" @click="copyKey(row.authKey)">
            <span class="icon">
              <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#088ef5" icon-name="icon-copy">
                <icon-copy />
              </icon-base>
            </span>
          </button>
          <!-- 새로 고침 버튼 주석 
            <v-btn v-if="row.authKey" class="btn btn-rotate" :disabled="subscription.status !== 'ACTIVE'"
            @click="refreshAuthKey">
            <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#088ef5" icon-name="icon-rotate">
              <icon-rotate />
            </icon-base>
          </v-btn> -->
          <v-btn v-else class="btn btn-xsmall btn-secondary" :disabled="subscription.status !== 'ACTIVE'"
            @click="createAuthKey">
            난수키 생성
          </v-btn>
        </div>
      </template>
      <template #lastPaymentDateTime="{ row }">
        <span>{{ formatDate(row.lastPaymentDateTime) }}</span>
      </template>
      <template #nextPaymentDateTime="{ row }">
        <span>{{ formatDate(row.nextPaymentDateTime) }}</span>
      </template>
      <template #usage="{ row }">
        <span>{{ row.usage }} 건</span>
      </template>
      <template #expectedAmount="{ row }">
        <span>{{ expectedAmount(row) }} 원</span>
      </template>
      <template #cancel="{ row }">
        <v-btn v-if="row.status === 'ACTIVE'" class="btn btn-xsmall btn-tertiary" @click="cancelMembership">
          구독 취소
        </v-btn>
      </template>
    </Table>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted,computed } from '@vue/composition-api';
import { membershipStore } from '@/stores/membership';
import Table from '@/components/membership/Table.vue';
import modalControler from '@/helper/modalControler';

export default {
  name: 'MembershipInfo',
  components: { Table },
  props: {
    customerId: {
      type: Number,
      required: false
        },
      },
  setup(props,context) {
    const route = context.root.$route
    const router = context.root.$router
    const store = membershipStore(); 

    const state = reactive({
      subscriptions: [] || null,
      subscription:null,
      copiedKey: '', 
      authKeyList: [] || nulll,
      authKey : null,
      usage : 0,
      price:null,
      authKeyId:null,

    });
    
    const resultType = computed(() => route.query.type);


    // 구독 목록의 난수키(인증키) 가져옴  
    const getAuthKey = async () => {
      try {
        await store.getAuthKey(); 
        state.authKeyList = store.authKeyList || []
        state.authKey = state.authKeyList[0]?.token ||null
        state.usage = state.authKeyList[0]?.usageCount || 0
      } catch (error) {
        console.error('Error fetching authKey:', error);
      }
    };


    // 구독 목록 조회 
    const getSubscriptionList = async () => {
      try {
        if (!props.customerId) {
          throw new Error('CustomerId is not set.');
        }
        await store.getSubscriptions(props.customerId); 
        state.subscriptions = store.subscriptions.result || [];

        // 각 구독 항목에 authKey,usage 추가
        state.subscriptions = state.subscriptions.map(subscription => {          
          return {
            ...subscription,
            authKey: state.authKey,
            usage: state.usage
          };
         
        }
      );
       // 임시로 0번 인덱스 구독 선택
        state.subscription = state.subscriptions[0] || null;
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
};

  onMounted(async () => {
        if (props.customerId) {
          await getAuthKey();
          await getSubscriptionList();
        }
      });


    const getColumns = () => {

      const subscription =  state.subscription
      
       if (subscription.status === 'ACTIVE') {
        return subscription.price.code === 'price_t8SAeTblq'
          ? [ // 종량제형 활성 상태
              { key: 'productName', label: '상품', width: '200px' },
              { key: 'authKey', label: '난수키' },
              { key: 'usage', label: '사용량', width: '120px' },
              { key: 'nextPaymentDateTime', label: '다음 결제일', width: '140px' },
              { key: 'expectedAmount', label: '예상 금액', width: '140px' },
              { key: 'cancel', label: '구독 취소', width: '100px' },
            ]
          : [ // 구독형 활성 상태
              { key: 'productName', label: '상품', width: '200px' },
              { key: 'authKey', label: '난수키' },
              { key: 'nextPaymentDateTime', label: '다음 결제일', width: '140px' },
              { key: 'expectedAmount', label: '예상 금액', width: '140px' },
              { key: 'cancel', label: '구독 취소', width: '100px' },
            ];
      } else { 
        return subscription.price.code === 'price_t8SAeTblq'
          ? [ // 종량제형 구독 취소 상태
              { key: 'productName', label: '상품', width: '200px' },
              { key: 'authKey', label: '난수키' },
              { key: 'usage', label: '사용량', width: '120px' },
              { key: 'lastPaymentDateTime', label: '마지막 결제일', width: '140px' },
            ]
          : [ // 구독형 구독 취소 상태
              { key: 'productName', label: '상품', width: '200px' },
              { key: 'authKey', label: '난수키' },
              { key: 'lastPaymentDateTime', label: '마지막 결제일', width: '140px' },
            ];
      }
    };

    //클립보드 복사 
    const copyKey = async (key) => {
      try {
        await navigator.clipboard.writeText(key);
        modalControler.ShowSuccessToast('클립보드에 복사되었습니다.');
      } catch (error) {
        modalControler.ShowErrorToast('복사에 실패했습니다.');
        console.error('Failed to copy key:', error);
      }
    };

    // 전체 상품명에서 플랜 이름만 반환
    const getPlanName = (text) => {
      if (!text) return null;
      const match = text.match(/\(([^)]+)\)/); 
      return match ? match[1] : ''; 
    };

    // 전체 상품명에서 상품 이름만 반환
    const getWithoutPlanName = (text) => {
      if (!text) return null;
      return text.replace(/\s*\([^)]*\)/, '').trim(); 
    };

    const createAuthKey = async () => {
        try {
          await store.createAuthKey();
          await getAuthKey(); 
          if (state.subscription){
            state.subscription.authKey = state.authKey; 
          }
        } catch (error) {
          console.error('인증키 생성 에러', error);
        }
      };
  
      const refreshAuthKey = async () => {
        try {
          const authKeyId = store.authKeyList[0]?.id;
          await store.createRefreshToken(authKeyId);
          await getAuthKey(); 
          if (state.subscription){
            state.subscription.authKey = state.authKey; 
          }
        } catch (error) {
          console.error('리프레시 토큰 생성 에러', error);
        }
      };

     // 구독 취소
    const cancelMembership = async () => {
      modalControler.ShowModalDialog(
        '구독 취소 확인',
        '구독을 취소하시겠습니까?',
        '취소',
        '확인',
        async (confirmed) => {
          if (confirmed) {
            try {
              await store.cancelSubscription('END_OF_PERIOD');
            {
                router.push({
                    name: 'MembershipResult',
                    query:{ type:'cancel_success' }
                })
            }
              
            } catch (error) {
              {
                router.push({
                    name: 'MembershipResult',
                    query:{ type:'cancel_fail' }
                })
            }
              console.error('Cancel Membership Error:', error);
            }
          } else {
            console.log('구독 취소가 취소되었습니다.');
            router.push({
                    name: 'MembershipResult',
                    query:{ type:'cancel_fail' }
                })
          }
        }
      );
    };
    
    const expectedAmount = (row) => {
      
        const freeLimit = 1000;
        const costPerUnit = 20;
        const basePrice = 10000;

        if (!row || !row.price) {
          console.error('-', row);
          return 0; 
        }
        if (row.price.code === 'price_t8SAeTblq') {
          // 종량제형 계산
          if (row.usage <= freeLimit) {
            return basePrice;
          } else {
            return basePrice + (row.usage - freeLimit) * costPerUnit;
          }
        } else {
      
          return row.price.price || 0; 
        }
      };

    const formatDate = (isoString) => {
      if (!isoString) return null;
      return isoString.split('T')[0]; 
    };

    return {
      ...toRefs(state),
      getColumns,
      copyKey,
      cancelMembership,
      getPlanName,
      getWithoutPlanName,
      formatDate,
      getAuthKey,
      createAuthKey,
      refreshAuthKey,
      expectedAmount
    };
  },
};
</script>

<style scoped>
.auth-key-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.mncpilot-authkey {
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
</style>