import Vue from 'vue';
import Router from 'vue-router';

// sub-routers
import CompetitionRouter from '@/pages/competition/CompetitionRouter';
import TaskRouter from '@/pages/task/TaskRouter';
import CommunityRouter from '@/pages/community/CommunityRouter';
import LeagueRouter from '@/pages/league/LeagueRouter';
import MembershipRouter from '@/pages/membership/MembershipRouter.js';

// pages
import Root from '@/pages/main/Root';
import Index from '@/pages/main/Index';
import LandingPage from '@/pages/landing/LandingPage';
import LandingPage2 from '@/pages/landing/LandingPage2';
import SignIn from '@/pages/auth/SignIn';
import SignInPage from '@/pages/auth/SignInPage';
import SignupRoot from '@/pages/auth/SignupRoot';
import SignupEmail from '@/pages/auth/SignupEmail';
import SignupSocial from '@/pages/auth/SignupSocial';
import Service from '@/pages/policy/Service';
import Privacy from '@/pages/policy/Privacy';
import CompetitionListPage from '@/pages/competition/CompetitionList'
import CompetitionDetailPage from '@/pages/competition/CompetitionDetail'

// auth libs
import Oauth from '@/lib/auth/Oauth';
import Oauth2 from '@/lib/auth/Oauth2';
import SSO from '@/lib/auth/SSO';

// store for authorization
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';

// libs
import modalControler from '@/helper/modalControler';

import Page404 from '@/pages/common/404';
import PageUiKit from '@/pages/common/UiKit';

Vue.use(Router);

/**
 * 
 *  Main Router Object
 * 
 */
const router = new Router({
    mode: 'history',
    duplicateNavigationPolicy: 'ignore',
    routes: [
        {
            path: '/',
            name: Root.name,
            component: Root,
            meta: {
                anonymousCallable: true
            }
        },
        {
            path: '/login',
            name: SignIn.name,
            component: SignIn,
            meta: {
                anonymousCallable: true
            }
        },
        {
            path: '/login/email',
            name: SignInPage.name,
            component: SignInPage,
            meta: {
                anonymousCallable: true
            }
        },
        {
            path: '/signup',
            name: SignupRoot.name,
            component: SignupRoot,
            redirect: {
                name: SignupEmail.name
            },
            children: [
                {
                    path: 'signup-email',
                    name: SignupEmail.name,
                    component: SignupEmail,
                    meta: {
                        anonymousCallable: true
                    }
                },
                {
                    path: 'signup-social',
                    name: SignupSocial.name,
                    component: SignupSocial,
                    meta: {
                        anonymousCallable: true
                    }
                },
            ],
            meta: {
                anonymousCallable: true
            }
        },
        {
            path: '/auth',
            name: Oauth.name,
            component: Oauth,
            meta: {
                anonymousCallable: true
            }
        },
        {
            path: '/auth/sso',
            name: SSO.name,
            component: SSO,
            meta: {
                anonymousCallable: true
            }
        },
        {
            path: '/404',
            name: Page404.name,
            component: Page404,
            meta: {
                anonymousCallable: true
            }
        },
         {
            path: '/UiKit',
            name: PageUiKit.name,
            component: PageUiKit,
            meta: {
                anonymousCallable: true
            }
        },
        // {
        //     path: '/policy/service',
        //     name: Service.name,
        //     component: Service,
        //     meta: {
        //         anonymousCallable: true
        //     }
        // },
        // {
        //     path: '/policy/privacy',
        //     name: Privacy.name,
        //     component: Privacy,
        //     meta: {
        //         anonymousCallable: true
        //     }
        // },
        CompetitionRouter,
        TaskRouter,
        CommunityRouter,
        LeagueRouter,
        MembershipRouter,
        {
            path: '*',
            redirect: '/404'
        },
    ],
    // Move scroll to Top
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});


/**
 *  Router guard
 * 
 */
router.beforeEach(async (to, from, next) => {
    const store = tokenStore()
    const store2 = stateStore()
    const competitionRouteList = [
        'CompetitionList', 
        'CommunityList', 
        'CompetitionNews',
        'LeagueList',
        'CodingTest',
        'MemberShip',
    ]
    const taskRouteList = ['TaskInfo', 'TaskLeaderboard', 'TaskTeam', 'CompetitionNotice', 'TaskData', 'TaskCodeShare', 'TaskSubmit', 'TaskNotice', 'LeagueData']
    const leagueRouteList = [
        'LeagueInfo',
        'LeagueData',
        'LeagueLeaderboard',
        'LeagueSubmit',
        'LeagueCommunity',
        'LeagueRule',
    ]

    if (competitionRouteList.includes(to.name)) {
        store2.setCompetitionActiveRoute(to.name)
    }

    if (taskRouteList.includes(to.name)) {
        store2.setTaskActiveRoute(to.name)
    }

    if (leagueRouteList.includes(to.name)) {
        store2.setTaskActiveRoute(to.name)
    }

    if (to.name !== 'SignInPage') {
        store2.setBeforeURL(from.fullPath)
    }

    // token 인증은 됬으니 userinfo가 유실된 경우 (refersh 등으로)
    if (store.isAuthenticated) {
        await store.userAuthenticate()
        if (!store.getAccountInfo) router.signout()
    }

    if (to.meta.anonymousCallable) {
        // 이미 로그인한 경우, 가입/로그인 페이지로 이동할 경우 메인 페이지로 이동시킨다
        if (to.name && store.isAuthenticated && [SignIn.name, SignInPage.name, SignupEmail.name].includes(to.name)) {
            if (store.isAuthenticated && store.getAccountInfo) {
                router.push({ name: 'CompetitionList' })
            } else {
                router.signout()
            }
            // router.goCompetitionPages('CompetitionList')
        }
        next()
    } else {
        if (store.isAuthenticated) {
            next()
        } else {
            router.push({ name: SignIn.name }).catch(err => err)
        }
    }
});


/**
 *  Router methods
 * 
 */
router.goHome = () => {
    router.push('/')
};

router.goPages = (name) => {
    router.push({ name: name })
}

router.signin = () => {
    router.push({ name: 'SignIn' })
}

router.signout = () => {
    localStorage.removeItem(process.env.VUE_APP_AUTH_TOKEN_STORE_KEY)
    localStorage.removeItem("firebase-device-token")
    localStorage.removeItem('temp-path')
    location.reload()
}

router.goCompetitionPages = (name, competitionId = null) => {
    if (name === 'CompetitionList') {
        router.push({ name: name })
    } else {
        router.push({ name: name, params: { competitionId: competitionId } })
    }
}

router.goTaskPages = (name, taskId, competitionId = null) => {
    router.push({ name: name, params: { taskId: taskId, competitionId: competitionId } })
}

router.goLeaguePages = (name, leagueId=null) => {
    if (name === 'LeagueList') {
        router.push({ name: name })
    } else {
        router.push({
            name: name,
            params: {
                leagueId: leagueId
            }
        })
    }
}

router.goQnaDetails = (name, qnaId, competitionId = null, taskId = null) => {
    router.push({ name: name, params: { qnaId: qnaId, competitionId: competitionId, taskId: taskId } })
}

// 'NavigationDuplicated' 예외 처리
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err;
    });
};


// Temp Router - 대회 홍보용
const router2 = new Router({
    mode: 'history',
    duplicateNavigationPolicy: 'ignore',
    routes: [
        {
            path: '/',
            name: LandingPage2.name,
            component: LandingPage2,
            meta: {
                anonymousCallable: true
            }
        },
        {
            path: '/signup',
            name: SignupRoot.name,
            component: SignupRoot,
            redirect: {
                name: SignupEmail.name
            },
            children: [
                {
                    path: 'signup-email',
                    name: SignupEmail.name,
                    component: SignupEmail,
                    meta: {
                        anonymousCallable: true,
                        tempPage: true          // 홍보용
                    }
                }
            ],
            meta: {
                anonymousCallable: true
            }
        },
        {
            path: '/auth',
            name: Oauth2.name,
            component: Oauth2,
            meta: {
                anonymousCallable: true
            }
        },
    ],
    // Move scroll to Top
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router;