<template>
  <div class="membership-wrap bg-contents-wrap">
    <header class="page-header__section pattern-section">
      <div class="page-header__inner">
        <div class="page-header__title-box">
          <h2 class="page-header__title">Membership</h2>
          <p class="page-header__sub-text">Code Assistant의 멤버십 회원이되어보세요!</p>
        </div>
      </div>
    </header>
    <section class="content-section">
      <div class="content-inner">
        <header class="content-section__header content-section--center">
          <h2 class="content-section__title">
            On-Premise 환경의 개발자를 위한 AI Code Assistant<br />
            글로벌 서비스와 동일한 성능을 더 안전한 환경에서 경험하세요.
          </h2>
          <p class="content-section__text">한국어 주석에 대한 높은 이해도를 바탕으로 폐쇄망 환경의 기업 맞춤형으로 실시간 코드를 제안합니다. <br />
            최신 버전의 라이브러리 및 코딩 스타일을 학습하여 정기적 업데이트하기 때문에
            제안된 코드의 높은 채택율을 유지하고 있습니다.
          </p>
        </header>
        <div class="box__items">
          <div class="box__item">
            <div class="box__img-box">
              <span class="icon icon__size--400 icon--api"><span class="blind">api</span></span>
            </div>
            <div class="box__text-box">
              <strong class="box__small-title">
                대규모 오픈소스 데이터셋 기반 지능형 코드 지원
              </strong>
            </div>
          </div>
          <div class="box__item">
            <div class="box__img-box">
              <span class="icon icon__size--400 icon--ai-server"><span class="blind">server</span></span>
            </div>
            <div class="box__text-box">
              <strong class="box__small-title">
                외부 서비스 연결 어려운 환경을 위해 On-Premise 형태로 지원
              </strong>
            </div>
          </div>
          <div class="box__item">
            <div class="box__img-box">
              <span class="icon icon__size--400 icon--code-update"><span class="blind">update</span></span>
            </div>
            <div class="box__text-box">
              <strong class="box__small-title">
                다양한 프로그래밍 언어 실시간 코드 권장 및 지속적 모델 업데이트
              </strong>
            </div>
          </div>
          <div class="box__item">
            <div class="box__img-box">
              <span class="icon icon__size--400 icon--ai-develop"><span class="blind">ai develop</span></span>
            </div>
            <div class="box__text-box">
              <strong class="box__small-title">
                글로벌 AI 코드 보조 프로그램에 상응하는 성능 및 한국어 주석에 대한 높은 이해도
              </strong>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="content-section--padding bg-gray">
      <div class="content-inner">
        <header class="content-section__header content-section--center">
          <h2 class="content-section__title">
            요금제
          </h2>
        </header>
        <div class="price-list__section">
          <div v-for="(membership, i) in store.membershipList" :key="'membership-'+i" class="price-box">
            <div class="price-box__head">
              <h3 class="title">{{ membership.planName }}</h3>
              <p class="text">매 월 정기적으로 요금을 지불하는 모델</p>
              <div class="price"><strong class="font-num-ebold">{{ membershipPlanPrice(membership.price) }}</strong> / {{ membershipPlanInterval('월') }}</div>
            </div>
            <div class="price-box__body">
              <ul>
                <li v-for="(item, j) in splitDescription(membership.planDescription)" :key="'membersihp-'+i+'desc'+j" class="list__item list__item--large ">
                  {{ item }}
                </li>
                <!-- <li class="list__item list__item--large">
                  예측 가능한 비용으로 안정적으로 서비스를 사용 가능
                </li> -->
              </ul>
            </div>
            <div class="price-box__foot">
              <v-btn @click="subscribe(membership)" class="btn btn-primary btn-full btn-large">구독하기</v-btn>
            </div>
          </div>
          <!-- <div class="price-box">
            <div class="price-box__head">
              <h3 class="title">종량제형 멤버십</h3>
              <p class="text">사용량에 따라 요금이 책정되는 모델</p>
              <div class="price"><strong class="font-num-ebold">10,000원 ~</strong> / 월</div>
            </div>
            <div class="price-box__body">
              <ul>
                <li class="list__item list__item--large ">
                  필요할 때마다 Code Assistant를 활용하여 효율적으로 비용을 관리 가능
                </li>
                <li class="list__item list__item--large">
                  원하는 만큼만 이용할 수 있어 예산을 보다 효과적으로 운영
                </li>
              </ul>
            </div>
            <div class="price-box__foot">
              <v-btn @click="subscribe()" class="btn btn-primary btn-full btn-large">구독하기</v-btn>
            </div>
          </div>
          <div class="price-box">
            <div class="price-box__head">
              <h3 class="title">프리미엄 멤버십</h3>
              <p class="text">매 월 정기적으로 요금을 지불하는 모델</p>
              <div class="price"><strong class="font-num-ebold">50,000원 ~</strong> / 월</div>
            </div>
            <div class="price-box__body">
              <ul>
                <li class="list__item list__item--large ">
                  기술 지원을 우선적으로 받을 수 있으며, 문제 해결 및 질문에 대한 신속한 대응을 보장
                </li>
                <li class="list__item list__item--large">
                  각 개발팀의 요구에 맞춰 최적화된 개발 환경을 제공
                </li>
              </ul>
            </div>
            <div class="price-box__foot">
              <v-btn @click="subscribe()" class="btn btn-primary btn-full btn-large">구독하기</v-btn>
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <section class="content-section">
      <div class="content-inner">
        <header class="content-section__header content-section--center">
          <h2 class="content-section__title">유의사항</h2>
        </header>
      </div>
      <div class="medium-content-inner">
        <ul>
          <li class="list__item list__item--large">요금은 매월 정해진 결제일에 청구되며, 등록된 본인 명의의 카드를 통해 결제됩니다.</li>
          <li class="list__item list__item--large">멤버십은 매월 자동으로 갱신됩니다. 원치 않는 경우, 갱신 일주일 전까지 취소 요청을 해주시기 바랍니다.</li>
          <li class="list__item list__item--large">구독형 멤버십은 구독 취소 시, 이용 기간이 종료된 후 자동으로 구독 취소됩니다.</li>
          <li class="list__item list__item--large">구독형 멤버십은 결제일로부터 7일 이내에 Code Assistant를 전혀 이용하지 않았을 때만 환불할 수
            있습니다.
            이후에는 환불이 불가능하니 신중히 결정해 주시기 바랍니다.
          </li>
          <li class="list__item list__item--large">종량제형 멤버십은 마이 멤버십에서 사용량을 확인할 수 있으며, 최종 사용량에 대한 요금이 청구됩니다. 사용량을
            정기적으로 확인하시기 바랍니다.
          </li>
          <li class="list__item list__item--large">
            종량제형 멤버십은 구독이 취소된 후 즉시 서비스 이용이 제한됩니다.
          </li>
          <li class="list__item list__item--large">
            종량제형 멤버십은 사용한 만큼 요금이 청구되므로, 환불이 불가능합니다. 사용량을 고려하여 신중하게 선택해 주세요.
          </li>
          <li class="list__item list__item--large">
            회원 탈퇴를 할 경우, 멤버십 복구 또는 환불이 불가능합니다.
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { 
  ref,
  reactive, 
  onMounted,
} from '@vue/composition-api';
import { membershipStore } from "@/stores/membership"


export default {
    name: 'MembershipList',
    components: {
        
    },
    setup(props, context) {
        const route = context.root.$route
        const store = membershipStore()
        const state = reactive({
            membershipList: []
        });

        const description = ref([]);

        // 특정 description을 \n 기준으로 분할하는 함수
        const splitDescription = (description) => {
          return description ? description.split('\n') : [];
        };

        onMounted(() => {
          store.getProducts()
        })

        // Membership 가격 표시
        const membershipPlanPrice = (price) => {
          return price.toLocaleString()
        }

        // Membership 기간 표시
        const membershipPlanInterval = (interval) => {
          // TODO : membership 결제 간격
          return interval
        }

        // Membership 결제 시도
        const subscribe = (membership) => {
          store.createOrder(membership)
        }

        return {
          store,
          subscribe,
          description,
          splitDescription,
          membershipPlanPrice,
          membershipPlanInterval
        }
    }
}
</script>