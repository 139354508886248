import MembershipRootPage from './MembershipRoot'
import MembershipListPage from './MembershipList'
import MembershipResultPage from './MembershipResult'


const membershipSubRoutes = [
    {
        path: 'list',
        title: 'Membership',
        name: MembershipListPage.name,
        component: MembershipListPage,
        meta: {
            anonymousCallable: true,
        }
    },
    {
        path: 'result',
        title: 'MembershipResult',
        name: MembershipResultPage.name,
        component: MembershipResultPage,
        // TODO : 개발 중에만
        meta: {
            anonymousCallable: true,
        }
    }
]

export default {
    path: '/membership',
    title: 'Membership',
    name: MembershipRootPage.name,
    component: MembershipRootPage,
    children: membershipSubRoutes,
    redirect: {
        name: MembershipListPage.name
    },
    meta: {
        anonymousCallable: true,
    }
}