import httpClient from "./httpClient";


export default {
    getCustomerInfo() {
        return httpClient.get(`/shop/steppay/user`)
    },
    agreeSteppayUse() {
        return httpClient.post(`/shop/steppay/agree`)
    },
    createCustomer() {
        return httpClient.post(`/shop/steppay/customers`)
    },
    deleteCustomer(customerId) {
        return httpClient.delete(`/shop/steppay/customers/${customerId}`)
    },
    getCustomerDetail(customerId) {
        return httpClient.get(`/shop/steppay/customers/${customerId}`)
    },
    createOrder(param) {
        return httpClient.post(`/shop/steppay/orders`, param)
    },
    getOrderUrl(orderId, param) {
        return httpClient.get(`/shop/steppay/orders/${orderId}/pay${param}`)
    },
    getProducts() {
        return httpClient.get(`/shop/steppay/products`)
    },
    getProductDetail(productCode) {
        return httpClient.get(`/shop/steppay/products/${productCode}`)
    },  
    //구독 조회 목록
    getSubscriptions(customerId) {
        return httpClient.get(`/shop/steppay/subscriptions`, {
            params: { customerId }, 
        });
    },
    getSubscriptionDetail(subscriptionId) {
        return httpClient.get(`/shop/steppay/subscriptions/${subscriptionId}`)
    },
    cancelSubscription(subscriptionId, whenToCancel = "END_OF_PERIOD") {
        return httpClient.post(`/shop/steppay/subscriptions/${subscriptionId}/cancel`, {
            whenToCancel, 
        });
    },
    // 인증키 조회
    getAuthKey(){
        return  httpClient.get(`/mncpilot/authkey`)
     },
    // 인증키 생성 
    createAuthKey(param){
        return  httpClient.post(`/mncpilot/authkey`,param)
    },
    // 리프레시 토큰 생성 
    createRefreshToken(authKeyId){
        return  httpClient.post(`/mncpilot/authkey/${authKeyId}/refresh`)
    },
   



}
